import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Redirect = () => {
    const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the desired URL after 0 seconds (instantaneously)
    setTimeout(() => {
        window.location.href ="https://gist.github.com/darrenli03/aac67905f286ab4069d745af2f643a31";
    }, 0);
  }, [navigate]);

    return (
        <div>
            <p>If you are not redirected, <a href="https://gist.github.com/darrenli03/aac67905f286ab4069d745af2f643a31">click here</a>.</p>
        </div>
    );
};

export default Redirect;
